import React from "react"
import "./getjobs.css"

function GetJobs() {
  return (
    <React.Fragment>
      <h4 className="pt-2">Get Jobs</h4>

      <div className="row">
        <div className="col-8">
          <p className="pt-2">
            Get all jobs which have been assigned to the organisation linked to
            the <code>ClientKey</code>.
          </p>
          <p className="pt-2">
            Jobs with the Virtual Helpdesk status <code>assigned</code> will be
            returned. For each job, the <code>acceptJob</code> API should be
            called which changes the job status to <code>accepted</code>{" "}
            ensuring the jobs wont be returned in subsequent calls to <code>getJobs</code>.
          </p>

          <div className="alert alert-primary" role="alert">
            <span className="badge text-bg-primary">GET</span>{" "}
            <span className="ps-2">
              <code>/api/getjobs</code>
            </span>
          </div>
          <h4>Security</h4>

          <div className="alert alert-light" role="alert">
            API Key
            <p>
              <code>Authorization</code> and <code>ClientKey</code> keys are
              required in the header. See Authentication
            </p>
          </div>
          <div className="card text-bg-light mb-3">
            <div className="card-header">Response</div>
            <div className="card-body">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Attribute</th>
                    <th scope="col">Type</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                
                <tr>
                    <th scope="row">jobNumber</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>
                      Unique string of the job number.
                    </td>
                  </tr>
                <tr>
                    <th scope="row">workRequestId</th>
                    <td>
                      <code>number</code>
                    </td>
                    <td>
                      Required for all subsequent API calls
                      related to this job
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">disciplineName</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>
                      Name of the Discipline. This is from a pre-defined list
                      which is editable by the customer
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">emergency</th>
                    <td>
                      <code>boolean</code>
                    </td>
                    <td>1 = emergency</td>
                  </tr>
                  <tr>
                    <th scope="row">logDate</th>
                    <td>
                      <code>string (yyyy=mm-dd HH:mm:ss)</code>
                    </td>
                    <td>DateTime (UTC) the job was assigned to the contractor</td>
                  </tr>
                  <tr>
                    <th scope="row">jobDetails</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>Description of the job</td>
                  </tr>
                  <tr>
                    <th scope="row">contactEmail</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>reporter of job email address</td>
                  </tr>
                  <tr>
                    <th scope="row">contactName</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>Reporter of job name</td>
                  </tr>
                  <tr>
                    <th scope="row">contactPhone</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>Reporter of job phone number</td>
                  </tr>
                  <tr>
                    <th scope="row">fullSiteName</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>Full path of location</td>
                  </tr>
                  <tr>
                    <th scope="row">slaName</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>Job priority</td>
                  </tr>
                  <tr>
                    <th scope="row">uprn</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>
                      Unique Property Reference Number. This is typically
                      unique to a site or building, not an individual location.
                      All locations within a building will have the same
                      (building) UPRN number
                    </td>
                  </tr>
                </tbody>
              </table>{" "}
            </div>
          </div>
          <div className="card text-bg-light mb-3">
            <div className="alert alert-success pb-2 pt-2" role="alert">
              <h5>
                {" "}
                <span className="largefont"> &#8226;</span> 200
              </h5>
            </div>

            <div className="card-body">
              <pre id="json">
                {JSON.stringify(
                  [
                    {
                      jobNumber: "2435-1",
                      workRequestId: 35334,
                      disciplineName: "Emergency lighting",
                      emergency: false,
                      logDate: "2024-04-17T13:48:12.93",
                      jobDetails: "Light flickering",
                      contactEmail: "support@virtual-helpdesk.com",
                      contactName: "James Smith",
                      contactPhone: "07777777777",
                      fullSiteName:
                        "Main Building North Wing/Ground Floor/Room 5",
                      slaName: "P2 - 8 Hours",
                      uprn: "WR5UFT6",
                    },
                    {
                      jobNumber: "345-1",
                      workRequestId: 34,
                      disciplineName: "Building/Heating",
                      emergency: false,
                      logDate: "2024-02-11T13:43:01.01",
                      jobDetails: "it is cold",
                      contactEmail: "john@virtual-helpdesk.com",
                      contactName: "John Smith",
                      contactPhone: "07777 777777",
                      fullSiteName:
                      "Main Building North Wing/First Floor/Kitchen",
                      slaName: "P2 - 8 Hours",
                      uprn: "WR5UFT6",
                    },
                    {
                      jobNumber: "4455-1",
                      workRequestId: 3453,
                      disciplineName: "Technician Tasks-Dispenser Installs",
                      emergency: false,
                      logDate: "2024-02-11T13:44:12.93",
                      jobDetails: "Please replace toilet seat",
                      contactEmail: "simon@virtual-helpdesk.com",
                      contactName: "Simon Smith",
                      contactPhone: "07777777777",
                      fullSiteName:
                      "Head Office/Third Floor/Reception/WC",
                      slaName: "P2 - 8 Hours",
                      uprn: "HY6UI8P",
                    },
                    {
                      jobNumber: "6755-1",
                      workRequestId: 3463,
                      disciplineName: "Electrical System",
                      emergency: false,
                      logDate: "2024-04-17T13:48:12.93",
                      jobDetails: "The power is out",
                      contactEmail: "christopherbwiles@gmail.com",
                      contactName: "Chris Wiles",
                      contactPhone: "07780618588",
                      fullSiteName:
                      "Head Office/First Floor/Warehouse",
                      slaName: "P2 - 8 Hours",
                      uprn: "HY6UI8P",
                    },
                  ],
                  null,
                  2
                )}
              </pre>{" "}
            </div>
          </div>
        </div>
        <div className="col-4">

        <div className="alert alert-light" role="alert">
        <section>
<code>
curl --location 'http://localhost:8080/api/getjobs' \
--header 'Authorization: AUTH_KEY' \
--header 'ClientKey: CLIENT_KEY'
</code>
          </section>
</div>

  </div>
      
     
      </div>
    </React.Fragment>
  )
}
export default GetJobs
