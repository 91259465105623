import React from "react"
import "./getjobs.css"

function AddFile() {
  return (
    <React.Fragment>
      <h4 className="pt-2">Add File</h4>

      <div className="row">
        <div className="col-8">
          <p className="pt-2">Add a file to a Job.</p>
          <p>A file can be added to any job regardless of the job status.</p>
          <div className="alert alert-success" role="alert">
            <span className="badge text-bg-success">POST</span>{" "}
            <span className="ps-2">
              <code>/api/addfile/:workRequestId</code>
            </span>
          </div>
          <h4>Security</h4>

          <div className="alert alert-light" role="alert">
            API Key
            <p>
              <code>Authorization</code> and <code>ClientKey</code> keys are
              required in the header. See Authentication
            </p>
          </div>

          <h4>Parameters</h4>

          <div className="alert alert-light" role="alert">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Parameter</th>
                  <th scope="col">Description</th>
                  <th scope="col">Parameter Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <code>
                      <b className="text-dark">workRequestId</b>
                    </code>
                  </th>
                  <td>
                    <code className="text-dark">
                      The workRequestId of the job relating to the file
                    </code>
                  </td>
                  <td>
                    {" "}
                    <code className="text-dark">path</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">file</code>
                  </th>
                  <td>
                    <code>max file size : 10MB</code>
                  </td>
                  <td>
                    <code className="text-dark">file</code>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="card text-bg-light mb-3">
            <div className="alert alert-success pb-2 pt-2" role="alert">
              <h5>
                {" "}
                <span className="largefont"> &#8226;</span> 200
              </h5>
            </div>

            <div className="card-body">Success</div>
          </div>
        </div>
        <div className="col-4">
          <div className="alert alert-light" role="alert">
            <section>
              <code>
                curl --location
                'http://localhost:8080/api/addfile/:workRequestId' \ --header
                'Authorization: AUTH_KEY' \ --header 'ClientKey: CLIENT_KEY' \
                --form 'file=@"/Users/pathToFile.svg"'
              </code>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default AddFile
