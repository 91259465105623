import React from "react"
import "./getjobs.css"

function AcceptJob() {
  return (
    <React.Fragment>
      <h4 className="pt-2">Accept Job</h4>

      <div className="row">
        <div className="col-8">
          <p className="pt-2">
            Change the status of a job to <code>accepted</code>. This will
            prevent it from being returned in the <code>getJobs</code> API.
          </p>
          <p className="pt-2">
            Its recommended that the external reference is provided.  This is recorded
            next to the Job and helps customers to match jobs in both systems.
          </p>
          <div className="alert alert-warning" role="alert">
            <span className="badge text-bg-warning">PUT</span>{" "}
            <span className="ps-2">
              <code>/api/acceptJob/:workRequestId/:externalReference</code>
            </span>
          </div>
          <div className="alert alert-warning" role="alert">
            <span className="badge text-bg-warning">PUT</span>{" "}
            <span className="ps-2">
              <code>/api/acceptJob/:workRequestId</code>
            </span>
          </div>
          <h4>Security</h4>

          <div className="alert alert-light" role="alert">
            API Key
            <p>
              <code>Authorization</code> and <code>ClientKey</code> keys are
              required in the header. See Authentication
            </p>
          </div>

          <h4>Parameters</h4>

          <div className="alert alert-light" role="alert">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Parameter</th>
                  <th scope="col">Description</th>
                  <th scope="col">Parameter Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <code>
                      <b className="text-dark">workRequestId</b>
                    </code>
                  </th>
                  <td>
                    <code className="text-dark">
                      The workRequestId of the job being accepted
                    </code>
                  </td>
                  <td>
                    {" "}
                    <code className="text-dark">path</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code>
                      <b className="text-dark">externalReference</b>
                    </code>
                  </th>
                  <td>
                    <code className="text-dark">
                      The externalReference from the external system
                    </code>
                  </td>
                  <td>
                    {" "}
                    <code className="text-dark">path</code>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="card text-bg-light mb-3">
            <div className="alert alert-success pb-2 pt-2" role="alert">
              <h5>
                {" "}
                <span className="largefont"> &#8226;</span> 200
              </h5>
            </div>

            <div className="card-body">Success</div>
          </div>
        </div>
        <div className="col-4">
        <div className="alert alert-light" role="alert">
            <section>
              <code>
                curl --location --request PUT
                'http://localhost:8080/api/acceptjob/:workRequestId/:externalReference' \
                --header 'Authorization: AUTH_KEY' \ --header 'ClientKey:
                CLIENT_KEY'
              </code>
            </section>
          </div>
        </div>

          <div className="alert alert-light" role="alert">
            <section>
              <code>
                curl --location --request PUT
                'http://localhost:8080/api/acceptjob/:workRequestId' \
                --header 'Authorization: AUTH_KEY' \ --header 'ClientKey:
                CLIENT_KEY'
              </code>
            </section>
          </div>

         
      </div>
    </React.Fragment>
  )
}
export default AcceptJob
