import React from "react"

function Introduction() {
  return (
    <React.Fragment>
        <div className="col-8">
      <h4 className="pt-2">Virtual Helpdesk REST APIs</h4>
      <p className="pt-2">
        Our Job APIs can be used to get jobs, and manage the lifecycle of those
        jobs. This enables third party job management systems to manage Virtual Helpdesk
        jobs whilst providing visibility to clients through Virtual Helpdesk on the
        status of each of those jobs.{" "}
      </p>
      <p className="pt-2">
        Our Planner API enables third party systems to complete PPM visits.{" "}
      </p>
      <p>URLs for the APIs to the dev and live environment will be provided when you receive your organisation API Key - see <code>authentication</code></p>
      <p>Virtual Helpdesk APIs produce and consume JSON</p>
      </div>
      <img src="./VHDJobAPIWorkflow.png" width="1000" alt="Virtual Helpdesk API Job Workflow"/>
      
    </React.Fragment>
  )
}
export default Introduction
