import React from "react"

import "./footer.css"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.bundle.js"

function Footer() {
  return (
    <React.Fragment>
      <footer className="footer bd-footer py-4 py-md-1  bg-body-tertiary text-white mt-auto">
        <div className="container  text-body-secondary">
             <div className="row mt-5">
              <div className="col-2">
                <img
                  width="140"
                  src="https://cdn.comply-x.com/logos/VHD-Blue-Navy.svg"
                  alt="Virtual Helpdesk"
                />
                <ul className="list-unstyled small">
                  <li className="mb-2">&#169; 2024 Virtual Helpdesk</li>
                  <li className="mb-2">Build 20454345</li>
                </ul>
              </div>
              <div className="col-1">
                <img
                  src="https://cdn.comply-x.com/icons/Cyber-Essentials-Logo-v2.svg"
                  height="70"
                  className="rounded ms-2"
                  alt="Cyber Essentials"
                ></img>
              </div>
              <div className="col-1">
                <img
                  src="https://cdn.comply-x.com/icons/ICORegistered.svg"
                  className="rounded float-end"
                  height="70"
                  alt="ICO Registered"
                ></img>
              </div>
              <div className="col-1"></div>

              <div className="col-4 align-self-end mb-3">
             
              </div>
              <div className="col-3 text-end">
                <a
                  href="https://uk.linkedin.com/company/virtual-helpdesk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="LinkedIn Logo"
                    src="https://complyxcxstorageaccount.blob.core.windows.net/icons/LinkedIn-Icon-White-Logo.svg"
                    height="37"
                  ></img>
                </a>

                <a
                  href="mailto: support@comply-x.com"
                  className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover px-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="45"
                    fill="currentColor"
                    className="bi bi-envelope"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          
      </footer>
      
    </React.Fragment>
  )
}

export default Footer
