import React from "react"
import "./menu.css"

function Menu({setMenuItem}) {

  return (
    <React.Fragment>
      <nav
        id="navbar-example3"
        className="navbar navbar-dark bg-dark h-100 flex-column align-items-stretch pe-4 border-end"
      >
        <nav className="nav nav-pills flex-column ps-3">
          <button onClick={() => setMenuItem("Introduction")} className="nounderline btn btn-link link-secondary text-start ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-house pb-1"
              viewBox="0 0 16 16"
            >
              <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
            </svg>
            &nbsp;&nbsp;Introduction
          </button>

          <button onClick={() => setMenuItem("Authentication")} className="nounderline btn btn-link link-secondary text-start">
            Authentication
          </button>
          <p className="fs-5 text-secondary pt-3">(Reactive) Job APIs</p>
          <button onClick={() => setMenuItem("GetJobs")} className="nounderline btn btn-link link-secondary text-start">
            Get Job List &nbsp;&nbsp;
            <span className="badge bg-primary valignauto">GET</span>
          </button>
          <button onClick={() => setMenuItem("AcceptJob")} className="nounderline btn btn-link link-secondary text-start">
            Accept Job &nbsp;&nbsp;
            <span className="badge bg-warning valignauto">PUT</span>
          </button>
           <button onClick={() => setMenuItem("SetPlannedVisitDate")} className="nounderline btn btn-link link-secondary text-start">
            Add Planned Date &nbsp;&nbsp;
            <span className="badge bg-warning valignauto">PUT</span>
          </button>
          <button onClick={() => setMenuItem("AddVisitDate")} className="nounderline btn btn-link link-secondary text-start">
            Add Visit Date &nbsp;&nbsp;
            <span className="badge bg-warning valignauto">PUT</span>
          </button>
          <button onClick={() => setMenuItem("AddMessage")} className="nounderline btn btn-link link-secondary text-start">
            Add Message to Job &nbsp;&nbsp;
            <span className="badge bg-success valignauto">POST</span>
          </button>
          <button onClick={() => setMenuItem("AddCost")} className="nounderline btn btn-link link-secondary text-start">
            Add Cost / Close Job &nbsp;&nbsp;
            <span className="badge bg-warning valignauto">PUT</span>
          </button>
          <button onClick={() => setMenuItem("AddFile")} className="nounderline btn btn-link link-secondary text-start">
            Add File to Job &nbsp;&nbsp;
            <span className="badge bg-success valignauto">POST</span>
          </button>

          <p className="fs-5 text-secondary pt-3">Phase 2 Reactive APIs</p>


          <button onClick={() => setMenuItem("AddAppointment")} className="nounderline btn btn-link link-secondary text-start">
            Add Appointment &nbsp;&nbsp;
            <span className="badge bg-success valignauto">POST</span>
          </button>

          <button onClick={() => setMenuItem("UpdateAppointment")} className="nounderline btn btn-link link-secondary text-start">
            Update Appointment &nbsp;&nbsp;
            <span className="badge bg-success valignauto">POST</span>
          </button>

          <button onClick={() => setMenuItem("UpdateWorkRequestStatus")} className="nounderline btn btn-link link-secondary text-start">
            Update Work Request Status &nbsp;&nbsp;
            <span className="badge bg-success valignauto">POST</span>
          </button>

          <button onClick={() => setMenuItem("CompleteJob")} className="nounderline btn btn-link link-secondary text-start">
            Complete Job &nbsp;&nbsp;
            <span className="badge bg-success valignauto">POST</span>
          </button>

          <p className="fs-5 text-secondary pt-3">Planner APIs</p>

          
          <button onClick={() => setMenuItem("GetPlanners")} className="nounderline btn btn-link link-secondary text-start">
            Get Planner List &nbsp;&nbsp;
            <span className="badge bg-primary valignauto">GET</span>
          </button>
          <button onClick={() => setMenuItem("AddPPMVisit")} className="nounderline btn btn-link link-secondary text-start">
            Add PPM Visit &nbsp;&nbsp;
            <span className="badge bg-success valignauto">POST</span>
          </button>
        </nav>
      </nav>
    </React.Fragment>
  )
}
export default Menu
