import React from "react"

function Authentication() {
  return (
    <React.Fragment>
      <div className="col-8">
      <h4 className="pt-2">Authentication</h4>
      <p className="pt-2">
        If you require an API key, please contact <a href="mailto:support@comply-x.com?subject=API Access Request">support@comply-x.com</a>.
      </p>
      <p className="pt-2">
        Authentication requires two API keys. The first relates to your organisation. This will be fixed for
        all integrations from your organisation to the Virtual Helpdesk API. The second
        key relates to a specific Virtual Helpdesk client.
      </p>
      <p className="pt-2">
        API keys are different for the dev and live systems.
      </p>
      <p className="pt-2">Header keys are required for GET and POST requests</p>
      </div>
      <div className="row">
        <div className="col-9">
          <div className="card text-bg-light mb-3">
            <div className="card-header">Request</div>
            <div className="card-body">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Parameter</th>
                    <th scope="col">Type</th>
                    <th scope="col">Position</th>
                    <th scope="col">#</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Authorization</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>
                      <code>Header</code>
                    </td>
                    <td>
                      <code>Required</code>
                    </td>
                    <td>
                      Organisation API Key - different for dev and live systems
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">ClientKey</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>
                      <code>Header</code>
                    </td>
                    <td>
                      <code>Required</code>
                    </td>
                    <td>Identifies the client</td>
                  </tr>
                </tbody>
              </table>{" "}
            </div>
          </div>
        </div>
        <div className="col-3"></div>
      </div>
    
    </React.Fragment>
  )
}
export default Authentication
