import React from "react"
import "./getjobs.css"

function GetPlanners() {
  return (
    <React.Fragment>
      <h4 className="pt-2">Get Planners</h4>

      <div className="row">
        <div className="col-8">
          <p className="pt-2">
            Get all planners which have been assigned to the organisation linked to
            the <code>Authorization</code> code.
          </p>
          <div className="alert alert-primary" role="alert">
            <span className="badge text-bg-primary">GET</span>{" "}
            <span className="ps-2">
              <code>/api/getplanners</code>
            </span>
          </div>
          <h4>Security</h4>

          <div className="alert alert-light" role="alert">
            API Key
            <p>
              <code>Authorization</code> key is
              required in the header. See Authentication
            </p>
          </div>
          <div className="card text-bg-light mb-3">
            <div className="card-header">Response</div>
            <div className="card-body">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Attribute</th>
                    <th scope="col">Type</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                
                <tr>
                    <th scope="row">plannedGuid</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>
                      Unique giud of the planner. Required for <code>Add PPM Visit</code>
                    </td>
                  </tr>
                <tr>
                    <th scope="row">repeatUnit</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>
                      <code>MONTH</code> or <code>WEEK</code>: frequency of the planner
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">repeatFrequency</th>
                    <td>
                      <code>number</code>
                    </td>
                    <td>
                     Repeat unit of the planner
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">activity</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>additional optional description relating to the planner task</td>
                  </tr>
                  <tr>
                    <th scope="row">disciplineName</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>Discipline the planner relates to</td>
                  </tr>
                  <tr>
                    <th scope="row">fullSiteName</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>Full location path of the location the planner relates to</td>
                  </tr>
                  <tr>
                    <th scope="row">clientName</th>
                    <td>
                      <code>string</code>
                    </td>
                    <td>Client the planner relates to</td>
                  </tr>
                </tbody>
              </table>{" "}
            </div>
          </div>
          <div className="card text-bg-light mb-3">
            <div className="alert alert-success pb-2 pt-2" role="alert">
              <h5>
                {" "}
                <span className="largefont"> &#8226;</span> 200
              </h5>
            </div>

            <div className="card-body">
              <pre id="json">
                {JSON.stringify(
                  [
                    {
                        "plannedGuid": "10E8FACC-ABB4-447B-A066-5AA6563323E6",
                        "repeatUnit": "MONTH",
                        "repeatFrequency": "6",
                        "activity": null,
                        "disciplineName": "Water Hygiene",
                        "fullSiteName": "Birmingham",
                        "clientName": "Big Hotel Limited"
                    },
                    {
                        "plannedGuid": "BEA77A08-15A7-468F-949C-4749A9F50EF1",
                        "repeatUnit": "MONTH",
                        "repeatFrequency": "1",
                        "activity": null,
                        "disciplineName": "Plumbing - Tap Temps",
                        "fullSiteName": "London",
                        "clientName": "Big Hotel Limited"
                    },
                    {
                        "plannedGuid": "1CD921A9-6639-4627-9517-AFC9CBD16BCC",
                        "repeatUnit": "MONTH",
                        "repeatFrequency": "12",
                        "activity": null,
                        "disciplineName": "Water Hygiene",
                        "fullSiteName": "Aberdeen",
                        "clientName": "Oil Refining Limited"
                    },
                    {
                        "plannedGuid": "0BCA4E82-F5AA-4C4D-A8C3-62A69B70DFE3",
                        "repeatUnit": "MONTH",
                        "repeatFrequency": "1",
                        "activity": null,
                        "disciplineName": "Plumbing - Tap Temps",
                        "fullSiteName": "Aldridge",
                        "clientName": "Pub Chain Limited"
                    }
                  ],
                  null,
                  2
                )}
              </pre>{" "}
            </div>
          </div>
        </div>
        <div className="col-4">

        <div className="alert alert-light" role="alert">
        <section>
<code>
curl --location 'http://localhost:8080/api/getplanners' \
--header 'Authorization: AUTH_KEY'
</code>
          </section>
</div>

  </div>
      
     
      </div>
    </React.Fragment>
  )
}
export default GetPlanners
