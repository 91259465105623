import React from "react"
import "./getjobs.css"

function AddAppointment() {
  return (
    <React.Fragment>
      <h4 className="pt-2">Add Appointment</h4>

      <div className="row">
        <div className="col-8">
          <p className="pt-2">
            Adds a new appointment (virtual helpdesk work request) to a job. 
          </p>
          <p>The new work request id will be returned which will be required for updating the appointment.</p>
          <p>Remote systems are more likely to have an existing work request id which relates to a job.  Therefore, this API will add a new work request
            to a job which relates to a supplied work request id.  The supplied work request id, and the new work request will not be linked in any way, except for belonging to the same job.
          </p>
          <div className="alert alert-success" role="alert">
            <span className="badge text-bg-success">POST</span>{" "}
            <span className="ps-2">
              <code>/api/v2/addappointment</code>
            </span>
          </div>
          <h4>Security</h4>

          <div className="alert alert-light" role="alert">
            API Key
            <p>
              <code>Authorization</code> and <code>ClientKey</code> keys are
              required in the header. See Authentication
            </p>
          </div>

          <h4>Parameters</h4>

          <div className="alert alert-light" role="alert">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Parameter</th>
                  <th scope="col">Description</th>
                  <th scope="col">Parameter Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <code>
                      <b className="text-dark">workRequestId</b>
                    </code>
                  </th>
                  <td>
                    <code className="text-dark">
                      A workRequestId, used to identify the Job to which the new work request will be added
                    </code>
                  </td>
                  <td>
                    {" "}
                    <code className="text-dark">body</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">scheduledStartDate</code>
                  </th>
                  <td>
                    <code className="text-dark">
                      date formatted yyyy-MM-ddTHH:mm:ss
                    </code>
                    <br />
                    <code className="text-dark">example</code>{" "}
                    <code>2023-12-01T14:05:00</code>
                  </td>
                  <td>
                  <code className="text-dark">body</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">scheduledFinishDate</code>
                  </th>
                  <td>
                    <code className="text-dark">
                      (Optional) date formatted yyyy-MM-ddTHH:mm:ss
                    </code>
                    <br />
                    <code className="text-dark">example</code>{" "}
                    <code>2023-12-01T14:05:00</code>
                  </td>
                  <td>
                  <code className="text-dark">body</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code>
                      <b className="text-dark">appointmentSlot</b>
                    </code>
                  </th>
                  <td>
                    <code className="text-dark">
                      (Optional) String which can be used to narrow down the appointment slot.
                      <br />
                      </code>
                    <code className="text-dark">examples</code>{" "}
                    <code>Morning | Afternoon | PM</code>
                   
                  </td>
                  <td>
                    {" "}
                    <code className="text-dark">body</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">message</code>
                  </th>
                  <td>
                    <code className="text-dark">
                      (Optional) message)
                    </code>
                  </td>
                  <td>
                    <code className="text-dark">body</code>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="card text-bg-light mb-3">
            <div className="alert alert-success pb-2 pt-2" role="alert">
              <h5>
                {" "}
                <span className="largefont"> &#8226;</span> 200
              </h5>
            </div>

            <div className="card-body">Success</div>
          </div>
        </div>
        <div className="col-4">
          <div className="alert alert-light" role="alert">
            <section>
              <code>
                curl --location
                'http://localhost:8080/api/addmessage/:workRequestId' \
                --header 'Authorization: AUTH_KEY' \ --header 'ClientKey:
                CLIENT_KEY' \ --header 'Content-Type: text/plain' \ --data
                'NOTE_TEXT'
              </code>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default AddAppointment
