import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

function createLogo(src, defaultAlt) {
  const logo = forwardRef(({
    alt, ...rest
  }, ref) => {
    return (
      <img
        ref={ref}
        src={src}
        alt={alt}
        {...rest}
      />
    );
  });
  
  logo.propTypes = {
    alt: PropTypes.string,
    title: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
  
  logo.defaultProps = {
    alt: defaultAlt,
    title: null,
    width: null,
    height: null,
  };

  return logo;
}

const VHDLogoWhite = createLogo("https://cdn.comply-x.com/logos/VHD-Landsacape-Navy-White.svg", "Virtual Helpdesk Logo");

export {
  VHDLogoWhite
};