import React from "react"
import "./getjobs.css"

function AddCost() {
  return (
    <React.Fragment>
      <h4 className="pt-2">Add Cost and Close Job</h4>

      <div className="row">
        <div className="col-8">
          <p className="pt-2">
            <b>Adding a cost to a Job will automatically close the Job.</b>
          </p>
          <p>
            Should you wish to close the job, but do not have any costs, then
            submit 0 cost.{" "}
          </p>
          <p>
            Ensure <code>addVisitDate</code> API has been called before calling{" "}
            <code>addCost</code>. Upto three costs can be set;{" "}
            <code>calloutCost</code>, <code>labourCost</code>,{" "}
            <code>materialCost</code>
          </p>

          <p>
            If only one cost is associated with the entire job then its
            recommended to use <code>calloutCost</code>.
          </p>

          <p>
            If the invoicing system is used within Virtual Helpdesk, once the
            job has been invoiced the cost cannot be changed.
          </p>

          <p>
            <code>completedate</code> and <code>canceldate</code> can be
            optionally added as a querystring parameter
          </p>

          <div className="alert alert-warning" role="alert">
            <span className="badge text-bg-warning">PUT</span>{" "}
            <span className="ps-2">
              <code>
                /api/addcost/:workRequestId/:calloutCost/:labourCost/:materialCost/
              </code>
            </span>
          </div>
          <div className="alert alert-warning" role="alert">
            <span className="badge text-bg-warning">PUT</span>{" "}
            <span className="ps-2">
              <code>
                /api/addcost/:workRequestId/:calloutCost/:labourCost/:materialCost?completedate=YYYY-MM-DDTHH:mm:ssZ
              </code>
            </span>
          </div>
          <div className="alert alert-warning" role="alert">
            <span className="badge text-bg-warning">PUT</span>{" "}
            <span className="ps-2">
              <code>
                /api/addcost/:workRequestId/:calloutCost/:labourCost/:materialCost?canceldate=YYYY-MM-DDTHH:mm:ssZ
              </code>
            </span>
          </div>
         
          <h4>Security</h4>

          <div className="alert alert-light" role="alert">
            API Key
            <p>
              <code>Authorization</code> and <code>ClientKey</code> keys are
              required in the header. See Authentication
            </p>
          </div>

          <h4>Parameters</h4>

          <div className="alert alert-light" role="alert">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Parameter</th>
                  <th scope="col">Description</th>
                  <th scope="col">Parameter Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <code>
                      <b className="text-dark">workRequestId</b>
                    </code>
                  </th>
                  <td>
                    <code className="text-dark">
                      The workRequestId of the job relating to the message
                    </code>
                  </td>
                  <td>
                    {" "}
                    <code className="text-dark">path</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">calloutCost</code>
                  </th>
                  <td>
                    <code className="text-dark">
                      Set to 0 for null value. Set this value if only a single
                      cost is applicable. Numerical value required with either 0
                      DP or 2DP
                    </code>
                  </td>
                  <td>
                    <code className="text-dark">path</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">labourCost</code>
                  </th>
                  <td>
                    <code className="text-dark">
                      Set to 0 for null value. Numerical value required with
                      either 0 DP or 2DP
                    </code>
                  </td>
                  <td>
                    <code className="text-dark">path</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">materialCost</code>
                  </th>
                  <td>
                    <code className="text-dark">
                      Set to 0 for null value. Numerical value required with
                      either 0 DP or 2DP
                    </code>
                  </td>
                  <td>
                    <code className="text-dark">path</code>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="card text-bg-light mb-3">
            <div className="alert alert-success pb-2 pt-2" role="alert">
              <h5>
                {" "}
                <span className="largefont"> &#8226;</span> 200
              </h5>
            </div>
            <div className="card-body">Success</div>
          </div>
        </div>
        <div className="col-4">
          <div className="alert alert-light" role="alert">
            <section>
              <code>
                curl --location --request PUT
                'http://localhost:8080/api/addcost/:workRequestId/:calloutCost/:labourCost/:materialCost/'
                \ --header 'Authorization: AUTH_KEY' \ --header 'ClientKey:
                CLIENT_KEY'
              </code>
            </section>
          </div>
          <div className="alert alert-light" role="alert">
            <section>
              <code>
                curl --location --request PUT
                'http://localhost:8080/api/addcost/:workRequestId/:calloutCost/:labourCost/:materialCost?canceldate=2024-01-02 12:03:23'
                \ --header 'Authorization: AUTH_KEY' \ --header 'ClientKey:
                CLIENT_KEY'
              </code>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default AddCost
