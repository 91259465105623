import React from "react"
import "./header.css"
import { VHDLogoWhite } from "../icons/Logos"

function Header() {
    return (
    <React.Fragment>
<nav className="navbar navbar-heading">

  <VHDLogoWhite width="229" className="ps-3" />


<p className="fs-4 pe-4 pt-2">API Documentation</p>
   

</nav>
</React.Fragment>
 )
}
export default Header