import React from "react"
import "./getjobs.css"

function AddPPMVisit() {
  return (
    <React.Fragment>
      <h4 className="pt-2">Add PPM Visit</h4>

      <div className="row">
        <div className="col-8">
          <p className="pt-2">Add a PPM Visit to a Planner.</p>
          <p>A file must be added containing the details of the job.</p>
          <div className="alert alert-success" role="alert">
            <span className="badge text-bg-success">POST</span>{" "}
            <span className="ps-2">
              <code>/api/addvisitdata/:plannerGuid</code>
            </span>
          </div>
          <h4>Security</h4>

          <div className="alert alert-light" role="alert">
            API Key
            <p>
              <code>Authorization</code> api key belonging to the contractor to which
              the planner is associated required in the header. See Authentication
            </p>
          </div>

          <h4>Parameters</h4>

          <div className="alert alert-light" role="alert">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Parameter</th>
                  <th scope="col">Description</th>
                  <th scope="col">Parameter Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <code>
                      <b className="text-dark">plannerGuid</b>
                    </code>
                  </th>
                  <td>
                    <code className="text-dark">
                      The plannerGuid of the planner to which you want to add a
                      PPM visit
                    </code>
                  </td>
                  <td>
                    {" "}
                    <code className="text-dark">path</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">file</code>
                  </th>
                  <td>
                    <code>max file size : 10MB</code>
                  </td>
                  <td>
                    <code className="text-dark">file</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">VisitDate</code>
                  </th>
                  <td>
                    <code className="text-dark">date formatted yyyy-MM-dd</code>
                    <br />
                    <code className="text-dark">example</code>{" "}
                    <code>2023-12-01</code>
                  </td>
                  <td>
                    <code className="text-dark">header</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">Compliance</code>
                  </th>
                  <td>
                    <code className="text-dark">Value is either </code><code>Compliant</code><code className="text-dark"> or </code><code>Non-Compliant</code>
                  </td>
                  <td>
                    <code className="text-dark">header</code>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="card text-bg-light mb-3">
            <div className="alert alert-success pb-2 pt-2" role="alert">
              <h5>
                {" "}
                <span className="largefont"> &#8226;</span> 200
              </h5>
            </div>

            <div className="card-body">Success</div>
          </div>
        </div>
        <div className="col-4">
          <div className="alert alert-light" role="alert">
            <section>
              <code>
              curl --location 'http://localhost:8080/api/addvisitdata/def3397a-f8a8-469a-70a4-af797b02b523' \
            --header 'Authorization: AUTH_KEY' \
            --header 'VisitDate: 2024-01-01' \
            --header 'Compliance: Compliant' \
            --form 'file=@"/Users/pathToFile.pdf"'
              </code>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default AddPPMVisit
